import React, { useState } from 'react'
import { Link } from 'gatsby'

const Form = () => {
  return (
    <>
      <section className="flex flex-wrap mb-10">
        <div className="md:flex-1 flex-auto md:mb-0 mb-5 md:mr-5 mr-0">
          <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd3MizKxduH_6vhVriAXoXGwdc2KATN0QiS6euAItQTadqYZw/viewform?embedded=true"
            width="100%"
            height="500"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </div>
        <div className="md:flex-1 flex-auto">
          <iframe src="https://www.google.com/maps/d/u/1/embed?mid=1wgd2n8Sv0wQygHDTo8u8dQxQlKdocHWR"
            width="100%"
            height="500"
          >
          </iframe>
        </div>
      </section>
      <Link to="/"
        title="Go Back"
        className="text-gray-400 text-sm font-medium hover:underline outline-none focus:outline-none">
        Go back
      </Link>
    </>
  )
}
export default Form
