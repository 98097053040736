import React from 'react'
import Links from './links'
import Form from './form'
import icon from '../images/icon.png'

const Modal = ({view}) => {

  return (
    <section className={`${view === 'list' ? "lg:max-w-4/10 left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-30 fixed max-w-8/10" : "lg:max-w-7/10  md:left-1/2  md:top-1/2  md:transform  md:-translate-x-1/2  md:-translate-y-1/2  md:z-30  md:fixed max-w-full"} w-full xl:p-14 p-10 bg-white rounded block text-center shadow-md`}>
      <img src={icon} alt="Pilipinas Pantry Network" className={`${view === 'list' ? "mb-6" : "mb-10" }  w-28 mx-auto`} />
      {
        view === 'list' ?
          <Links />
        :
          <Form />
      }
    </section>
  )
}

export default Modal




