import React, { useState } from 'react'
import { Link } from 'gatsby'

const Links = () => {
  const links = [
    {
      title: 'I want to donate',
      url: 'https://google.com'
    },
    {
      title: 'I want to start a community pantry',
      url: '/form',
      interior: true,
    },
    {
      title: 'I already have a community pantry and want to source supplies from our farmers',
      url: 'https://mayani.ph/pages/the-grand-bagsakan'
    },
  ];

  return (
    <>
      <h1 className="text-2xl font-bold text-gray-700 mb-14">
        Pilipinas Pantry Network
      </h1>
      {
        links.map((link, key) => 
          !link.interior
            ? (
              <a href={link.url}
                key={key}
                title={link.title}
                className="font-medium block text-center mb-6 bg-561C py-3.5 px-5 text-white rounded-sm hover:bg-361C transition duration-150 ease-in-out outline-none focus:outline-none"
              >
                {link.title}
              </a>                  
            ) : (
              <Link to={link.url}
                key={key}
                title={link.title}
                className="font-medium block text-center mb-6 bg-561C py-3.5 px-5 text-white rounded-sm hover:bg-361C transition duration-150 ease-in-out">
                {link.title}
              </Link>
            )
          )
      }
    </>
  )
}

export default Links
